import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { Meta } from "../components/Meta";

const WelcomePage: React.FC = () => (
  <>
    <Meta title="Home" />
    <Box>
      <Typography variant="h1" align="center" sx={{ mt: 10, mb: 3 }}>
        Welcome to Codex.
      </Typography>
      <Paper elevation={3}>
        <Typography variant="body1" sx={{ p: 2 }} align="center">
          <strong>Codex</strong> is a collaboratively developed library of
          artifacts that enable the predictable and efficient delivery of high
          quality software at Bellese. <strong>Codex</strong> will let us
          describe our solution and how it is built, deployed and improved.{" "}
          <strong>Codex</strong> will serve as a version controlled point of
          collaboration for development of these artifacts and a focal point for
          learning and improvement.
        </Typography>
      </Paper>
    </Box>
  </>
);

export default WelcomePage;
